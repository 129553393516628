import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import {
  Heading,
  Text,
  Container,
  Box,
  Flex,
  Button
} from '@chakra-ui/react'

import { PrismicRichText, SliceZone } from '@prismicio/react'
import { components } from "../../components/Slices"
import SnowscapesLogo from "../../images/snowscapesLogo.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageSubMenu from "../../components/PageSubMenu/PageSubMenu"


const RegisterPage = ({ data, location }) => {
  const doc = data.prismicSnowscapesPage.data;

  console.log(doc)

  return (
    <Layout>
      {doc.submenu.document !== null &&
      <PageSubMenu 
        menuItems={doc.submenu.document.data.page_submenu}
        location={location}
      />}
      <main>
        <Container maxW="container.xl">
          <Flex alignItems="center" textAlign="center" flexDirection="column">
            {/* <Box w="60%" my="4">
              <img src={SnowscapesLogo} alt="Snowscapes Logo" />
            </Box> */}
            {/* <Box mb="12">
              <GatsbyImage image={getImage(doc.hero_image.localFile)} alt={doc.hero_image.alt} />
            </Box> */}
            <Box mt="12">
              <Heading as="h1" fontSize="5xl" mb="4">
                {doc.title.text}
              </Heading>
              {/* <Heading as="h2" variant="h2" fontFamily="Roboto, sans-serif" fontSize="clamp(1rem, 2vw, 1.25rem)">
                {doc.page_subtitle.text}
              </Heading> */}
            </Box>
          </Flex>
        </Container>
        <SliceZone slices={doc.body} components={components} />
      </main>
    </Layout>
  )
}

export const query = graphql`
query SnowscapesPageQuery {
  prismicSnowscapesPage(uid: {eq: "register"}) {
    uid
    data {
      title {
        text
      }
      body {
        ... on PrismicSnowscapesPageDataBodyNewsletterSignup {
          id
          slice_type
          primary {
            newsletter_signup_title {
              text
            }
            newsletter_signup_subtitle {
              text
            }
            newsletter_signup_button_label
            newsletter_signup_background_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        ... on PrismicSnowscapesPageDataBodyDowntownEvents {
          id
          primary {
            justify_image
            button_label
            button_link {
              url
            }
            event_content {
              richText
            }
            event_logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
                publicURL
              }
              alt
            }
            event_title {
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
          items {
            event_fact_title {
              text
            }
            event_fact_stat {
              text
            }
          }
          slice_type
        }
        ... on PrismicSnowscapesPageDataBodyCallToAction {
          id
          slice_type
          primary {
            link_label
            link {
              url
            }
            call_to_action_title {
              text
            }
            call_to_action_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            call_to_action_content {
              richText
            }
          }
        }
        ... on PrismicSnowscapesPageDataBodyRichText {
          id
          slice_type
          items {
            content {
              richText
            }
          }
        }
      }
      submenu {
        document {
          ... on PrismicPageSubmenu {
            id
            data {
              page_submenu {
                menu_item_link {
                  url
                }
                menu_item_label
              }
            }
          }
        }
      }
      page_content {
        richText
      }
    }
  }
}
`

export default RegisterPage

export const Head = ({ data }) => <title>{data.prismicSnowscapesPage.data.title.text}</title>
